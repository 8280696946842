var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$apollo.loading ? _c('TalpaLoaderWrapper') : _c('AssetInformationStyled', [_c('WidgetHeaderMolecule', {
    staticClass: "title",
    attrs: {
      "title": _vm.$t('widgetTypes.ASSET_INFORMATION'),
      "assetDimensions": []
    }
  }), !_vm.isSingleAssetView ? _c('NoDataBlockStyled', [_vm._v(" " + _vm._s(_vm.$t('messages.incompatibleDashboardType')) + " ")]) : _vm._e(), _vm.assetData && _vm.isSingleAssetView ? [_c('header', [_vm.iconName ? _c('TalpaIcon', {
    staticClass: "icon",
    attrs: {
      "scope": 'AssetTypes',
      "name": _vm.iconName
    }
  }) : _vm._e(), _c('h2', [_vm._v(_vm._s(_vm.assetData.name))])], 1), _c('div', {
    staticClass: "column"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$tc('model', 1)))]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.assetData.model.name))])]), _vm.assetData.customerId ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$tc('technicalID', 1)))]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.assetData.customerId))])]) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$tc('lastUpdate', 1)))]), _c('div', {
    staticClass: "value"
  }, [_c('TimeAgo', {
    attrs: {
      "time": _vm.assetData.lastLog
    }
  })], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$tc('totalMachineHours', 1)))]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.engineHours))])]), _vm.assetData.lastPosition && _vm.assetData.lastPosition.geofence ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$tc('geofence', 1)))]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.assetData.lastPosition ? _vm.assetData.lastPosition.geofence : ''))])]) : _vm._e()]), _vm.iconName ? _c('TalpaIcon', {
    staticClass: "icon-large background",
    attrs: {
      "scope": 'AssetTypes',
      "name": _vm.iconName
    }
  }) : _vm._e()] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }