<template>
  <TalpaLoaderWrapper v-if="$apollo.loading" />
  <AssetInformationStyled v-else>
    <WidgetHeaderMolecule :title="$t('widgetTypes.ASSET_INFORMATION')" :assetDimensions="[]" class="title" />
    <NoDataBlockStyled v-if="!isSingleAssetView"> {{ $t('messages.incompatibleDashboardType') }} </NoDataBlockStyled>
    <template v-if="assetData && isSingleAssetView">
      <header>
        <TalpaIcon v-if="iconName" class="icon" :scope="'AssetTypes'" :name="iconName" />
        <h2>{{ assetData.name }}</h2>
      </header>
      <div class="column">
        <div class="row">
          <div class="label">{{ $tc('model', 1) }}</div>
          <div class="value">{{ assetData.model.name }}</div>
        </div>
        <div class="row" v-if="assetData.customerId">
          <div class="label">{{ $tc('technicalID', 1) }}</div>
          <div class="value">{{ assetData.customerId }}</div>
        </div>
        <div class="row">
          <div class="label">{{ $tc('lastUpdate', 1) }}</div>
          <div class="value">
            <TimeAgo :time="assetData.lastLog" />
          </div>
        </div>
        <div class="row">
          <div class="label">{{ $tc('totalMachineHours', 1) }}</div>
          <div class="value">{{ engineHours }}</div>
        </div>
        <div class="row" v-if="assetData.lastPosition && assetData.lastPosition.geofence">
          <div class="label">{{ $tc('geofence', 1) }}</div>
          <div class="value">{{ assetData.lastPosition ? assetData.lastPosition.geofence : '' }}</div>
        </div>
      </div>
      <TalpaIcon v-if="iconName" class="icon-large background" :scope="'AssetTypes'" :name="iconName" />
    </template>
  </AssetInformationStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import { TimeframeMixin } from '@common/mixins'
import localesMixin from '@/mixins/locales'
import { TalpaIcon, TimeAgo, TalpaLoaderWrapper } from '@common/components'
import { flexCenter, flexColumns, flexStartCenter } from '@styles/mixins'
import units from '@/utils/units'
import WidgetHeaderMolecule from '@/components/Atomic/Molecules/WidgetHeaderMolecule'
import { useAssetStore } from '@/stores/assets'

import ASSET_INFORMATION_QUERY from '#/graphql/operations/asset/assetInformationCardQuery.gql'

const AssetInformationStyled = styled('div')`
  ${flexColumns}
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
  overflow: hidden;
  > .title {
    padding: 0;
  }
  > header {
    ${flexStartCenter}
    padding: 0.5rem;
    .icon {
      margin-right: 1rem;
    }
    h2 {
      margin: 0;
    }
  }

  .column {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 0.5rem;
    .row {
      ${flexCenter}
      margin-top: .4rem;
      .label {
        width: 12rem;
        text-align: left;
        color: ${p => p.theme.colors.muted};
      }
      .value {
        flex-grow: 1;
        margin-left: 0.5rem;
      }
    }
  }

  .icon-large.background {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.035;
    bottom: 0;
    left: 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`
const NoDataBlockStyled = styled('div')`
  ${flexCenter}
  height: 100%;
  padding: 0.5rem;
`

export default {
  name: 'AssetInformation',
  setup() {
    const assetStore = useAssetStore()
    return {
      assetStore,
    }
  },
  mixins: [TimeframeMixin, localesMixin],
  props: {
    widget: {
      type: Object,
      required: true,
    },
  },
  components: {
    TalpaLoaderWrapper,
    AssetInformationStyled,
    TalpaIcon,
    TimeAgo,
    WidgetHeaderMolecule,
    NoDataBlockStyled,
  },
  computed: {
    isSingleAssetView() {
      return Boolean(this.assetStore.isSingleAssetView)
    },
    singleSelectedAsset() {
      return this.assetStore.singleSelectedAsset()
    },
    iconName() {
      return get(this.singleSelectedAsset, 'type.name', '').split(' ').join('')
    },
    assetData() {
      const assetInfoFromStore = this.singleSelectedAsset
      return { ...assetInfoFromStore, ...this.asset }
    },
    engineHours() {
      let machineHours = '-'
      if (this.assetData?.lastPosition) {
        machineHours =
          this.assetData?.lastPosition?.totalMachineHours !== 0
            ? units(
                this.assetData?.lastPosition?.totalMachineHours,
                '',
                '',
                2,
                false,
                true,
                false,
                this.thousandsSeperator,
                this.decimalSeperator,
                true,
              )
            : '-'
      }

      return machineHours
    },
  },
  apollo: {
    asset: {
      query: ASSET_INFORMATION_QUERY,
      variables() {
        return {
          id: this.singleSelectedAsset?.id,
        }
      },
      skip() {
        return !this.singleSelectedAsset?.id
      },
    },
  },
}
</script>
